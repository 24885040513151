/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, State, Action} from 'vuex-class'
// import { CustomerState } from '@/store/customer/types';
import { ModelAccountState } from '@/store/modelAccount/types'
import { PayedInformation } from '@/components/pages/model-account/payment-information/model-account-payment-information.interface'
import router from "@/route/routing";
import * as locker from "@/store/locker.js"
import Dropbox from "@/store/dropbox.js"
import * as messageModal from "@/store/message-modal";

const namespace: string = 'modelAccount';

export default class ModelAccountPaymentInformationPage extends Vue {
	@State('modelAccount') modelAccount: ModelAccountState | undefined;
	@Action('setPaymentInformation', {namespace}) setPaymentInformation: any;

	paymentInformation: PayedInformation = {
		minPayed: '',
		typePayed: ''
	}

    Dropbox(e : any) {
        Dropbox(e);
    }

	onSubmit() {
        locker.Show();

		//this.setPaymentInformation(this.paymentInformation);


        locker.Hide();
        router.push({name: 'model-account-agreement-pp'}).then(

		);

		this.paymentInformation = {
			minPayed: '',
			typePayed: ''
		}

	}

    mounted() {
        messageModal.Init();
    }
}
